import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
export default function MainValues() {
  return (
    <div>
        <Row>
            <Col>
            <Card>
            <Card.Body>
              <Card.Title><i class="fa fa-magic" aria-hidden="true" style={{fontSize:"40px"}}></i>
              <p style={{fontSize:"30px"}}>Creativity and Innovation</p>
              </Card.Title>
              <Card.Text>
              <p style={{textAlign:"left", fontStyle:"italic"}}>
                <li>A commitment to pushing design boundaries and creating unique, visually stunning decorations.</li>
                <li>Embracing new trends and styles while maintaining a strong brand identity.</li>
                <li>Fostering a culture of experimentation and artistic expression.</li>
            </p>
              </Card.Text>
            </Card.Body>
          </Card>
            </Col>
            <Col>
            <Card>
            <Card.Body>
              <Card.Title><i class="fa fa-users" aria-hidden="true"></i>
              <p style={{fontSize:"30px"}}>Customer Centricity</p>
              </Card.Title>
              <Card.Text>
              <p style={{textAlign:"left"}}>
              <li>Prioritizing the client's vision and preferences above all else.</li>
            <li>Building strong relationships based on trust, communication, and personalized service.</li>
            <li>Striving for exceptional customer satisfaction and exceeding expectations.</li>
            </p>
              </Card.Text>
            </Card.Body>
          </Card>
            </Col>
            <Col>
            <Card>
            <Card.Body>
              <Card.Title><i class="fa fa-certificate" aria-hidden="true"></i>
              <p style={{fontSize:"30px"}}>Quality and Craftsmanship</p>
              </Card.Title>
              <Card.Text>
              <p style={{textAlign:"left"}}>
                <li>Using high-quality materials and employing skilled artisans.</li>
                <li>Paying meticulous attention to detail in every aspect of the decoration process.</li>
                <li>Delivering products and services that are durable, elegant, and long-lasting.</li>
             </p>
              </Card.Text>
            </Card.Body>
          </Card>
            </Col>
        </Row>
    </div>

  )
}
