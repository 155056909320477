export const decorList=[{
    id:1,
    header:"Stage Design 1",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage1.jpg"),
    amount:"10000"
},{
    id:2,
    header:"Stage Design 2",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage2.jpg"),
    amount:"10000"
},{
    id:3,
    header:"Stage Design 3",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage3.jpg"),
    amount:"10000"
},{
    id:4,
    header:"Stage Design 4",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage4.jpg"),
    amount:"10000"
},{
    id:5,
    header:"Stage Design 5",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage5.jpg"),
    amount:"10000"
},{
    id:6,
    header:"Stage Design 6",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage6.jpg"),
    amount:"10000"
},{
    id:7,
    header:"Stage Design 7",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage7.jpg"),
    amount:"10000"
},{
    id:8,
    header:"Stage Design 8",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage8.jpg"),
    amount:"10000"
},{
    id:9,
    header:"Stage Design 9",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage9.jpg"),
    amount:"10000"
},{
    id:10,
    header:"Stage Design 10",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage10.jpg"),
    amount:"10000"
},{
    id:11,
    header:"Stage Design 11",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage11.jpg"),
    amount:"10000"
},{
    id:12,
    header:"Stage Design 12",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage12.jpg"),
    amount:"10000"
},{
    id:13,
    header:"Stage Design 13",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage13.jpg"),
    amount:"10000"
},{
    id:14,
    header:"Stage Design 14",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage14.jpg"),
    amount:"10000"
},{
    id:15,
    header:"Stage Design 15",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage15.jpg"),
    amount:"10000"
},{
    id:16,
    header:"Stage Design 16",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage16.jpg"),
    amount:"10000"
},{
    id:17,
    header:"Stage Design 17",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage17.jpg"),
    amount:"10000"
},{
    id:18,
    header:"Stage Design 18",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage18.jpg"),
    amount:"10000"
},{
    id:19,
    header:"Stage Design 19",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage19.jpg"),
    amount:"10000"
},{
    id:20,
    header:"Stage Design 20",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage20.jpg"),
    amount:"10000"
},{
    id:21,
    header:"Stage Design 21",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage21.jpg"),
    amount:"10000"
},{
    id:22,
    header:"Stage Design 22",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage22.jpg"),
    amount:"10000"
},{
    id:23,
    header:"Stage Design 23",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage23.jpg"),
    amount:"10000"
},{
    id:24,
    header:"Stage Design 24",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage24.jpg"),
    amount:"10000"
},{
    id:25,
    header:"Stage Design 25",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage25.jpg"),
    amount:"10000"
},{
    id:26,
    header:"Stage Design 26",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage26.jpg"),
    amount:"10000"
},{
    id:27,
    header:"Stage Design 27",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage27.jpg"),
    amount:"10000"
},{
    id:28,
    header:"Stage Design 28",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage28.jpg"),
    amount:"10000"
},{
    id:29,
    header:"Stage Design 29",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage29.jpg"),
    amount:"10000"
},{
    id:30,
    header:"Stage Design 30",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage30.jpg"),
    amount:"10000"
},{
    id:31,
    header:"Stage Design 31",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage31.jpg"),
    amount:"10000"
},{
    id:33,
    header:"Stage Design 33",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage33.jpg"),
    amount:"10000"
},{
    id:32,
    header:"Arch 1",
    info:"Welcome Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/arch1.jpg"),
    amount:"2000"
},{
    id:34,
    header:"Balloon Arch 1",
    info:"Balloon Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/balloon1.jpg"),
    amount:"2000"
},{
    id:35,
    header:"Balloon Arch 2",
    info:"Welcome Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/balloon2.jpg"),
    amount:"2000"
},{
    id:36,
    header:"Balloon Arch 3",
    info:"Welcome Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/balloon3.jpg"),
    amount:"2000"
},{
    id:37,
    header:"Flower Arch",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch.jpg"),
    amount:"2000"
},{
    id:38,
    header:"Flower Arch 1",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch1.jpg"),
    amount:"2000"
},{
    id:39,
    header:"Flower Arch 2",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch2.jpg"),
    amount:"2000"
},{
    id:40,
    header:"Flower Arch 3",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch3.jpg"),
    amount:"2000"
},{
    id:41,
    header:"Flower Arch 4",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch4.jpg"),
    amount:"2000"
},{
    id:42,
    header:"Flower Arch 5",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch5.jpg"),
    amount:"2000"
},{
    id:43,
    header:"Flower Arch 6",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch6.jpg"),
    amount:"2000"
},{
    id:44,
    header:"Name Board 1",
    info:"Name Board",
    category:"NameBoard",
    status:"Active",
    imgSrc:require("./assests/img/nameboard/nameboard1.jpg"),
    amount:"2000"
},{
    id:45,
    header:"Arch 2",
    info:"Welcome Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/arch2.jpg"),
    amount:"2000"
},{
    id:46,
    header:"Arch 3",
    info:"Welcome Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/arch3.jpg"),
    amount:"2000"
},{
    id:47,
    header:"Stage Design 32",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage32.jpg"),
    amount:"10000"
},{
    id:48,
    header:"Stage Design 34",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage34.jpg"),
    amount:"10000"
},{
    id:49,
    header:"Stage Design 35",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage35.jpg"),
    amount:"10000"
},{
    id:50,
    header:"Stage Design 36",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage36.jpg"),
    amount:"10000"
},{
    id:51,
    header:"Stage Design 37",
    info:"Full stage design",
    category:"Stage",
    status:"Active",
    imgSrc:require("./assests/img/stage/stage37.jpg"),
    amount:"10000"
},{
    id:52,
    header:"Flower Arch 7",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch7.jpg"),
    amount:"2000"
},{
    id:53,
    header:"Flower Arch 8",
    info:"Flower Arch",
    category:"Arch",
    status:"Active",
    imgSrc:require("./assests/img/arch/flowerarch8.jpg"),
    amount:"2000"
},{
    id:54,
    header:"Name Board 2",
    info:"Name Board",
    category:"NameBoard",
    status:"Active",
    imgSrc:require("./assests/img/nameboard/nameboard2.jpg"),
    amount:"2000"
},{
    id:55,
    header:"Name Board 3",
    info:"Name Board",
    category:"NameBoard",
    status:"Active",
    imgSrc:require("./assests/img/nameboard/nameboard3.jpg"),
    amount:"2000"
},{
    id:56,
    header:"Name Board 4",
    info:"Name Board",
    category:"NameBoard",
    status:"Active",
    imgSrc:require("./assests/img/nameboard/nameboard4.jpg"),
    amount:"2000"
}];

export const bannerList=[{
    id:1,
    imgSrc:require("./assests/img/banner/banner1.jpg"),
},{
    id:2,
    imgSrc:require("./assests/img/banner/banner2.jpg"),
},{
    id:3,
    imgSrc:require("./assests/img/banner/banner3.jpg"),
},{
    id:4,
    imgSrc:require("./assests/img/banner/banner4.jpg"),
}];