import React, { useState } from 'react';
import {  Link, useNavigate } from "react-router-dom";
import {Navbar, Nav, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";
import logo from '../assests/logo.png';

export default function NavbarTop() {
  

    const [collapsed, setCollapsed] = useState(true);
   // const [isOpen, setIsOpen] = useState(false);
  const [downcollapse, setdropcollapse]=useState(true);
    const toggleNavbar = () => {
     // console.log("clicked");
        setCollapsed(!collapsed);
    }
const toggledropdown = ()=>{
  setdropcollapse(!downcollapse);
}



  return (
    <div className='navbar-main'>
        
     <Navbar
    dark
    expand="md"
    light
    className='sticky-top p-0'
  >
  
          <NavbarBrand href="/" style={{marginRight:'0px'}}>
        <h3 className='mb-0 text-white'>
    <img className='m-2 img-fluid navbarnew-brand-logo' src={logo}   alt="rr-logo" /> RR Decorations  </h3>
   
    </NavbarBrand>

    <NavbarToggler 
      className="navbarToggleEff me-2" 
      onClick={toggleNavbar}
    />
    <div >
    <Collapse isOpen={!collapsed} navbar>
       
            <Nav id="navbar" className="navbar order-last order-lg-0 ml-auto"    
         navbar
      >
        <ul>
        
              <NavItem className="nav-link scrollto">
          <NavLink className='active'>
            <Link to={"/"}>
            Home
            </Link>
          </NavLink>
        </NavItem>
         
      
        <NavItem className="nav-link scrollto">
          <NavLink  >
            <Link to={"/about"}>About Us
            </Link>
          </NavLink>
        </NavItem>
       
      {/*}  <NavItem className="nav-link scrollto">
          <NavLink    >
            Courses
          </NavLink>
        </NavItem>
       
        <NavItem className="nav-link scrollto">
          <NavLink    >
            Gallery
          </NavLink>
        </NavItem>*/}
       
       
       
       {/*} <UncontrolledDropdown   className="nav-link scrollto"
                                inNavbar
                                nav
                                >
                                <DropdownToggle
                                    caret
                                    nav
                                >
                               Register
                                </DropdownToggle>
                                <DropdownMenu >
                                    <DropdownItem >
                                   
            Register
         
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem >
                                    Application Status
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem  >
                                    Student Portal
                                    </DropdownItem>
                                </DropdownMenu>
                                </UncontrolledDropdown>*/}
         <NavItem className="nav-link scrollto">
          <NavLink  >
         <Link to={"/contact-us"}>
         Contact Us
         </Link>
          </NavLink>
        </NavItem>
      </ul>
      </Nav> 
    </Collapse> 
      </div>

      </Navbar>
    </div>
  )
}
