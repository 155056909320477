import React from 'react'
import Intro from './Intro'
import MainValues from './MainValues'

export default function MainAbout() {
  return (
    <div>
        <Intro />
        <MainValues />
    </div>
  )
}
