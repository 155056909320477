import React,{useEffect, useState} from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ViewDecorImages from './ViewDecorImages';
import { decorList } from '../../../RawData';
import s from '../../../assests/img/stage/stage1.jpg'
import Footer from '../Footer';
import MainAbout from '../about/MainAbout';
/*import sp1 from '../../../assests/img/speakers/1.jpg';
import sp2 from '../../../assests/img/speakers/2.jpg';
import sp3 from '../../../assests/img/speakers/3.jpg';
import sp4 from '../../../assests/img/speakers/4.jpg';
import sp5 from '../../../assests/img/speakers/5.jpg';
import sp6 from '../../../assests/img/speakers/6.jpg';
import sp7 from '../../../assests/img/speakers/7.jpg';
import sp8 from '../../../assests/img/speakers/8.jpg';
import sp9 from '../../../assests/img/speakers/9.jpg';
import sp10 from '../../../assests/img/speakers/10.jpg';

const decmainview=[{img:[sp1], header:"Stage Design 1", info:"Full stage design", amt:"10000", category:"Stage"},
                    {img:[sp2], header:"Stage Design 2", info:"Full stage design", amt:"10000", category:"Stage"},
                    {img:[sp3], header:"Stage Design 3", info:"Full stage design", amt:"10000", category:"Stage"},
                    {img:[sp4], header:"Balloon Arch", info:"Balloon Arch", amt:"10000", category:"Arch"},
                    {img:[sp5], header:"Stage Design 5", info:"Full stage design", amt:"10000", category:"Stage"},
                    {img:[sp6], header:"Balloon Arch", info:"Enterance Arch", amt:"10000", category:"Arch"},
                    {img:[sp7], header:"GOLD Square - 1", info:"Gold Square", amt:"10000", category:"NameBoard"},
                    {img:[sp8], header:"GOLD Square - 2", info:"Gold Square", amt:"10000", category:"NameBoard"},
                    {img:[sp9], header:"Circle", info:"Circle NameBoard", amt:"10000", category:"NameBoard"},
                    {img:[sp10], header:"Name Board Arch ", info:"Name Board Arch", amt:"10000", category:"Arch"}
];*/

export default function HomeDecor() {

    const [tabValue, setTabValue]=useState("Home");
    const [displayImage, setDisplayImage] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(()=>{
      setDisplayImage(decorList);
      setLoaded(true);
    },[])

    const handleHeaderChange=(k)=>{
      console.log("key" , k);
      setLoaded(false);
      if(k==="Home"){
        setDisplayImage(decorList);  
      }else{
        const tempFilter=decorList.filter((item)=>item.category===k);
        console.log("tempFileter : ", tempFilter);
        setTabValue(k);
        setDisplayImage(tempFilter);  
      }
      setLoaded(true);
    }

  return (
    <div>
      <MainAbout />
        
    <section id="speakers">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <h2>Your Dreams - Our Craftsmanship</h2>
          <p>Discover your dream decor in a single click.</p>
        </div>
        <Tabs fill defaultActiveKey="Home" id="uncontrolled-tab-example"       
      onSelect={(k) => handleHeaderChange(k)}
    className="mb-3">
    <Tab  eventKey="Home" title="All">
        {loaded && <ViewDecorImages displayImage={displayImage}/>}
    </Tab>
  <Tab eventKey="Stage" title="Stage">
      {loaded && <ViewDecorImages displayImage={displayImage}/>}
  </Tab>
  <Tab eventKey="Arch" title="Arch">
  {loaded && <ViewDecorImages displayImage={displayImage}/>}
  </Tab>
  <Tab eventKey="NameBoard" title="NameBoard">
  {loaded && <ViewDecorImages displayImage={displayImage}/>}
  </Tab>
  </Tabs>
      </div>
    </section>
    </div>
  )
}
