
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarTop from './components/NavbarTop';
import {Helmet} from "react-helmet-async";
import { Route, Routes } from 'react-router-dom';
import ContactUs from './components/pages/ContactUs';
import HomeDecor from './components/pages/DecorView/HomeDecor';
import Footer from './components/pages/Footer';
import MainAbout from './components/pages/about/MainAbout';

function App() {
  return (
    <div className="App">
      <Helmet>
      <title>R R Decors - Chennai</title>
      <meta name="description" content="R R Decors - Chennai" />
      <meta name="keywords" content="R R Decors - Chennai" />
      <link rel="canonical" href="/" />
    </Helmet>
      <NavbarTop/>
      <Routes>
      <Route path="/" element={<HomeDecor />} />
      <Route path="/about" element={<MainAbout />} />
      <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
