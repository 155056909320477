import React from 'react';
import {Row, Col, Carousel} from 'react-bootstrap';
import { bannerList } from '../../../RawData';

export default function () {
  return (
    <div style={{marginTop:"20px", padding:"20px"}}>
      <Row>
        <Col xs={6}>
        <Carousel fade controls={false} indicators={false}>
          {bannerList.map((item, index)=>
      <Carousel.Item key={index}>
        <img src={item.imgSrc} style={{width:"100%", height:"350px", borderRadius:"20px"}}/>
      </Carousel.Item>)}
      </Carousel>
        </Col>
        <Col>
        <p style={{fontFamily:"sans-serif", fontSize:"25px", fontStyle:"italic", textAlign:"justify"}}>We're not just decorators; we're architects of ambiance. Our designs are more than eye-catching; they're soul-stirring. Each space we touch becomes a canvas for our creativity. From minimalist elegance to opulent grandeur, we cater to every vision. Our passion is to transform ordinary spaces into extraordinary experiences. With a blend of art and functionality, we create environments that resonate. Let us weave magic into your surroundings. Your dream space starts here.</p>
        </Col>
      </Row>
    </div>
  )
}
