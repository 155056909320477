import React from 'react';
import '../../App.css'
import rrlogo from '../../assests/logo.png';

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#222", color: "#fff", padding: "20px"}}>
    <div className='row'>
      <div className='col' style={{textAlign:"start"}}>
        <img src={rrlogo} style={{width:"180px", height:"80px"}}/>
        <p>107, Padikuppam Road, Kamaraj nagar,</p>
        <p>Chennai – 600040</p>
        <ul className="social-icons">
          {/* Social media icons here */}
        </ul>
      </div>
      <div className='col'>
        <p>(+91) - 97890 91634</p>
        <p>josephrathinaraj@gmail.com</p>
        <p>copyright  <i class="fa fa-copyright"></i> <a href="https://idntica.com">idntca</a></p>
      </div>
      </div>
    </div>
  );
};

export default Footer;