import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';

export default function ViewDecorImages(props) {
    const {displayImage} = props;
    const [show,setShow] = useState(false);
    const [zoomImage, setZoomImage]=useState({imgSrc:'',header:''});

    const handleZoomImage=(event, image, header)=>{
      event.preventDefault();
      setZoomImage({imgSrc:image,header:header});
      setShow(true);
    }

    const handleClose=()=>{
      setZoomImage({imgSrc:'',header:''});
      setShow(false);
    }

  return (
    <div>
      <Modal show={show} onHide={handleClose} animation size="xl" centered>
      <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {zoomImage.header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <img src={zoomImage.imgSrc} style={{width:"100%", height:"100%"}} />
        </Modal.Body>
      </Modal>
        <div className="row">
          {displayImage.map(({imgSrc,header,info,amount})=><div className="col-lg-4 col-md-6">
          <div className="speaker" data-aos="fade-up" data-aos-delay="100">
            <img src={imgSrc} alt="Speaker 1" className="img-fluid" style={{height:"350px", width:"450px"}} onClick={event=>handleZoomImage(event, imgSrc, header )}/>
            <div className="details">
              <h3><a href="speaker-details.html">{header}</a></h3>
              <p>{info} - Rs. {amount}</p>
             {/*} <div className="social">
                <a href=""><button className='btn-warning rounded'><i className="fa fa-shopping-cart"> + ADD </i></button></a>
              </div>*/}
            </div>
          </div>
          </div>)}
      </div>
    </div>
  )
}
